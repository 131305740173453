import { CurrencySelector } from "@circle-react-shared/Billing/CurrencySelector";
import { Typography } from "@circle-react-uikit/Typography";

export const LineItem = ({
  displayName,
  amountWithoutCurrency,
  displayIntervalText,
  shouldShowCurrencySelection,
  billingCurrency,
  setBillingCurrency,
  description,
}) => (
  <div>
    <div className="flex flex-row justify-between">
      <div>
        <div className="mt-3.5">
          <Typography.LabelSm weight="semibold" color="text-default">
            {displayName}
          </Typography.LabelSm>
        </div>
        <div className="mt-1 flex items-baseline gap-1">
          <Typography.TitleXl>{amountWithoutCurrency}</Typography.TitleXl>
          <Typography.LabelSm weight="medium" color="text-default">
            {displayIntervalText}
          </Typography.LabelSm>
        </div>
        <div className="mt-2.5">
          <Typography.LabelSm>{description}</Typography.LabelSm>
        </div>
      </div>
      {shouldShowCurrencySelection && (
        <CurrencySelector
          billingCurrency={billingCurrency}
          onCurrencyChange={currency => setBillingCurrency(currency)}
        />
      )}
    </div>
    <div className="border-primary mt-5 border-b" />
  </div>
);
