import PropTypes from "prop-types";
import { t } from "i18n-js";
import { Link } from "react-router-dom";
import { planSettingsPath } from "@/react/helpers/urlHelpers";
import { usePunditUserContext } from "@circle-react/contexts";
import { getNextTier } from "@circle-react/helpers/planHelpers";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Typography } from "@circle-react-uikit/Typography";
import { LineItem } from "./LineItem";
import { useUpgradePlanContext } from "./UpgradePlanContext";

const i18nRoot = "pricing_plans.upgrade_modal";

export const PlanDetails = ({
  subheading = "",
  hideModal,
  onSubmitHandler,
}) => {
  const {
    billingCurrency,
    setBillingCurrency,
    plan,
    marketingHubPlan,
    shouldShowCurrencySelection,
    isMarketingHubRequired,
    isMarketingHubOnlyUpgrade,
  } = useUpgradePlanContext();

  const { currentCommunity } = usePunditUserContext();
  const { current_plan_tier: currentPlanTier } = currentCommunity;

  const learnMoreRedirect = () => (window.location.href = planSettingsPath());

  const getDisplayIntervalText = planObj =>
    `/ ${planObj.display_interval.toLowerCase()}`;

  const nextTier = getNextTier(currentPlanTier);

  let everythingInCopy;
  if (isMarketingHubOnlyUpgrade) {
    everythingInCopy = "marketing_hub_features_title";
  } else if (nextTier === plan?.tier) {
    everythingInCopy = `everything_in_${plan.tier}`;
  } else {
    everythingInCopy = "everything_in_your_plan_plus";
  }

  const featuresToDisplay =
    plan?.main_features || marketingHubPlan?.main_features || [];

  const onClickHandler = () => {
    onSubmitHandler();
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          {t([i18nRoot, "title"], {
            plan: plan?.display_name || marketingHubPlan?.display_name,
          })}
        </Modal.Title>

        <Modal.HeaderActions>
          <Modal.CloseButton onClick={hideModal} />
        </Modal.HeaderActions>
      </Modal.Header>
      <Modal.Body>
        {subheading && (
          <>
            <Typography.LabelSm weight="semibold" color="text-default">
              {isMarketingHubOnlyUpgrade
                ? t(
                    "pricing_plans.upgrade_modal.marketing_hub_value_prop_title",
                  )
                : subheading}
            </Typography.LabelSm>
            <div className="border-primary mt-5 border-b" />
          </>
        )}

        {plan && (
          <LineItem
            displayName={t([i18nRoot, "plan_name"], {
              plan: plan.display_name,
            })}
            amountWithoutCurrency={plan.amount_without_currency}
            displayIntervalText={getDisplayIntervalText(plan)}
            shouldShowCurrencySelection={shouldShowCurrencySelection}
            billingCurrency={billingCurrency}
            setBillingCurrency={setBillingCurrency}
            description={t([i18nRoot, `${plan.tier}_description`])}
          />
        )}

        {isMarketingHubRequired && marketingHubPlan && (
          <LineItem
            displayName={t([i18nRoot, "marketing_hub_plan_name"], {
              plan: marketingHubPlan.display_name,
            })}
            amountWithoutCurrency={marketingHubPlan.amount_without_currency}
            displayIntervalText={getDisplayIntervalText(marketingHubPlan)}
            shouldShowCurrencySelection={false}
            description={t([i18nRoot, "marketing_hub_description"])}
          />
        )}

        <div className="mt-6">
          <Typography.LabelLg weight="semibold">
            {t([i18nRoot, everythingInCopy])}
          </Typography.LabelLg>
        </div>

        {featuresToDisplay.length > 0 && (
          <ul className="mt-6 flex list-none flex-col gap-5">
            {featuresToDisplay.map((feature, index) => {
              const itemKey = `feature-item-${index}`;
              return (
                <li key={itemKey} className="flex items-center gap-3">
                  <Icon
                    type="20-checkmark-blue"
                    size={20}
                    className="!h-5 !w-5"
                  />
                  <Typography.LabelMd weight="medium">
                    {feature}
                  </Typography.LabelMd>
                </li>
              );
            })}
          </ul>
        )}

        {!isMarketingHubOnlyUpgrade && (
          <div className="mt-5">
            <Link onClick={learnMoreRedirect}>
              <Typography.LabelMd weight="medium" color="text-default">
                {t([i18nRoot, "learn_more"])}
              </Typography.LabelMd>
            </Link>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="mt-5">
        <Button variant="circle" large full onClick={onClickHandler}>
          {t([i18nRoot, "actions.upgrade"], {
            plan: plan?.display_name || marketingHubPlan?.display_name,
          })}
        </Button>
      </Modal.Footer>
    </>
  );
};

PlanDetails.propTypes = {
  subheading: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
};
